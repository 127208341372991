<template>
  <div>
    <h1 class="-mt-3">MUSEUM ARCHITEKTURY</h1>
    <p>MUSA chce být také paměťovou institucí, a&nbsp;proto bude usilovat o&nbsp;získání kompletního díla či reprezentativního výběru prací žijících velikánů české moderní architektury. V&nbsp;případě současné tvorby pak MUSA vyzve autory napříč naší scénou, aby věnovali výběr svých prací a&nbsp;ten v&nbsp;průběhu let pravidelně doplňovali. A&nbsp;protože se dnes už projektuje výhradně na&nbsp;počítačích, budeme preferovat drobné skici, kresby či koláže, zkrátka práce s&nbsp;povahou klasických sbírkových předmětů. Ostatně kresba je pro architekturu klíčová, neboť jsou do nich vepsány prvotní myšlenky, koncepty a&nbsp;hlavně celý proces hledání realizovatelného řešení.<br>Proto chceme připravovat monografické, monotematické a&nbsp;tematické retrospektivní výstavy včetně publikací a&nbsp;doprovodných akcí. Jejich protiváhou pak budou výstavy, workshopy, brainstormingy akcentující současnou dobu a&nbsp;její kruciální témata.</p>
    <address>Jakub Potůček</address>
    <div class="grid lg:grid-cols-2 lg:grid-rows-3 lg:grid-flow-col mt-12 gap-8">
      <div class="mb-6">
        <h1>URBANISMUS</h1>
        <p>Nová urbanita širší lokality Nákladového nádraží Žižkov.</p>
      </div>
      <div class="mb-6">
        <h1>ZACHOVÁNÍ</h1>
        <p>Zachování významné historické industriální stopy.</p>
      </div>
      <div class="mb-6"> 
        <h1>PREZENTACE TRADICE</h1>
        <p>Prezentace české architektonické tradice na světové úrovni.</p>
      </div>
      <div class="mb-6">
        <h1>PODPORA</h1>
        <p>Podpora vzniku kulturně-společenského klastru.</p>
      </div>
      <div class="mb-6">
        <h1>DIVERZIFIKACE</h1>
        <p>Diverzifikace turistické a&nbsp;kulturní obslužnosti v&nbsp;hlavním městě Praze jak pro obyvatele Prahy, tak pro návštěvníky.</p>
      </div>
      <div>
        <h1 class="hidden lg:block invisible">X</h1>
        <address>Dan Merta</address>
      </div>
    </div>
    <lightgallery v-masonry="" item-selector=".masonry-item" transition-duration="0.3s" gutter="32" :settings="{ speed: 500 }" class="lg:-mr-8 mt-12 pointer-children">
      <img v-masonry-tile class="masonry-item" src="../assets/images/01-museum-architektury/MUSA-NNZ-5.jpg" alt="" rel="preload">
      <img v-masonry-tile class="masonry-item" src="../assets/images/01-museum-architektury/MUSA-NNZ-2.jpg" alt="" rel="preload">
      <img v-masonry-tile class="masonry-item" src="../assets/images/01-museum-architektury/MUSA-NNZ-3.jpg" alt="" rel="preload">
      <img v-masonry-tile class="masonry-item" src="../assets/images/01-museum-architektury/MUSA-NNZ-4.jpg" alt="" rel="preload">
      <img v-masonry-tile class="masonry-item" src="../assets/images/01-museum-architektury/MUSA-NNZ-1.jpg" alt="" rel="preload">
    </lightgallery>
    <lightgallery class="grid lg:grid-cols-2 gap-8 pointer-children">
      <img src="../assets/images/01-museum-architektury/MUSA-NNZ-6.jpg" alt="" rel="preload">
      <img src="../assets/images/01-museum-architektury/MUSA-NNZ-7.jpg" alt="" rel="preload">
    </lightgallery>
  </div>
</template>

<script>
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import Lightgallery from 'lightgallery/vue';

export default {
  name: 'Museum',
  components: {
    Lightgallery,
  },
  mounted() {
    window.scrollTo({ top: 0});
  },
}
</script>

<style>
  .masonry-item{
    width: calc(33.3% - 32px);
    margin-bottom: 30px;

  }

  @media only screen and (max-width: 1024px) {
    .masonry-item{
    width: calc(100%);
    margin-bottom: 30px;
    }
  }
</style>
